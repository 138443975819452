<template>
  <div class="anim-wrapper">
    <div class="backdrop" @click="close()" />
    <div class="Detail">
      <div class="v-center">
        <div class="inner">
          <div class="row-12">
            <div class="scroll col-5 lg:col-4 v-center p-10">
              <div class="text">
                <h1 v-html="payload.caption" />
                <div v-html="payload.text" />
              </div>
            </div>
            <div class="col-7 lg:col-8 img lg:pl-0">
              <Close class="close right-4 md:right-10" @click="close" />

              <transition name="default">
                <Image
                  v-if="other.length"
                  :src="other[0]"
                  class="swap"
                  @click="swap"
                />
              </transition>

              <swiper
                v-if="!payload.map"
                :pagination="{ clickable: true }"
                :navigation="true"
                :lazy="{ loadPrevNext: true }"
                @swiper="onSwiper"
              >
                <swiper-slide
                  v-for="slide of serie"
                  :key="slide.id"
                  class="slide"
                >
                  <img :src="slide.large" :alt="slide.alt" class="swiper-lazy">
                </swiper-slide>
              </swiper>

              <Map v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import Close from '@/assets/images/close.svg';
import SwiperCore, { A11y, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

const Map = defineAsyncComponent(() => import('@/components/part/Map.vue'));

SwiperCore.use([A11y, Pagination, Navigation]);

export default {
  components: {
    Map,
    Swiper,
    SwiperSlide,
    Close,
  },
  props: {
    payload: { type: Object, default: Object },
  },
  emits: ['close'],
  data() {
    return {
      open: false,
      serie: this.payload.hauptserie,
      other: this.payload.sekundarserie,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    swap() {
      const tmp = this.serie;
      this.serie = this.other;
      this.other = tmp;
      this.swiper.slideTo(0, 0);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.anim-wrapper {
  position: relative;
  z-index: 150;
  transform-origin: 50vw 50vh;
}

.backdrop {
  content: "";
  position: fixed;
  z-index: 140;
  top: -15vh;
  left: -15vw;
  width: 130vw;
  height: 130vh;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
}

.Detail {
  position: fixed;
  z-index: 150;

  will-change: transform;

  --border: 1rem;
  top: var(--border);
  left: var(--border);
  width: calc(100vw - var(--border) * 2);
  height: calc(100vh - var(--border) * 2);

  clip-path: url(#cutout-big);
  border-radius: 20px;
  overflow: hidden;

  color: var(--color-secondary);

  @screen lg {
    --border: 4rem;
  }

  @media (max-height: 1000px) {
    --border: 2rem;
  }

  .scroll {
    overflow-y: auto;
    padding-top: 25vh;
  }

  .v-center {
    height: 100%;
  }

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    background: white;
  }

  .row-12 {
    --gutter-x: 0;
    height: 100%;
  }

  .text {
    position: relative;
    z-index: 10;
    display: block;
    width: 100%;
    margin-top: auto;
  }

  :deep(h1) {
    @apply mb-4;
    hyphens: auto;
  }

  :deep(.img) {
    position: relative;
    height: 40%;
    background-size: cover;

    @screen md {
      height: 100%;
    }
  }

  .swap {
    position: absolute;
    left: 45px;
    bottom: 30px;
    z-index: 10;
    width: 130px;
    height: 130px;
    border-radius: 10px;
    border: 2px solid var(--color-secondary);
    box-shadow: inset 0px 0px 200px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .swiper-container {
    height: 100%;
    background: var(--color-secondary);
  }

  img {
    height: 100%;
    object-fit: cover;
  }

  .close {
    position: absolute;
    top: 50px;
    width: 40px;
    z-index: 10;
    transition: 0.25s ease;
    color: white;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 1));
    &:hover {
      color: var(--color-primary);
      cursor: pointer;
    }
  }
}
</style>
